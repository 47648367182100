export enum QueryKey {
  REZEPT_SUCHE = "rezepte-suche",
  REZEPT_DETAIL = 'rezept-detail'
}

export enum LocalStorageKey {
  REZEPT_EDIT = 'REZEPT_EDIT',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
}

