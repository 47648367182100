export enum Einheit {
  ST = "ST",
  DZ = "DZ",
  G = "G",
  KG = "KG",
  LB = "LB",
  ML = "ML",
  TL = "TL",
  EL = "EL",
  L = "L",
  CP = "CP"
}
