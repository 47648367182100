export function LebensmittelAdmin() {
  return (<>
    <h1>Lebensmittel Verwaltung</h1>
    <ul>
      <li>useQuery</li>
      <li>Table View</li>
      <li>Edit Mask</li>
    </ul>
  </>)
}
