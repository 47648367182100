import React from "react";
import {ConditionalDisplay} from "../../layout/ConditionalDisplay";


/**
 * DataSync Component
 * @component Einkaufsliste
 */
export function EinkaufslisteView(): React.ReactElement {
  return (<ConditionalDisplay restricted>



  </ConditionalDisplay>)
}

