export enum LebensmittelKategorie {
  Milchprodukte = 'Milchprodukte',
  Mehl = 'Mehl',
  Essig = 'Essig',
  OEL = 'Öl',
  Rindfleisch = 'Rindfleisch',
  Schweinefleisch = 'Schweinefleisch',
  Pasta = 'Pasta',
  Kartoffeln = 'Kartoffeln',
  Kaese = 'Käse',
  Saucen = 'Saucen',
}
