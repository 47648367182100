import React from "react";

/**
 * TS Doc Info
 * @component ZuletztGekocht
 */
export function ZuletztGekocht(): React.ReactElement {
  return (<div>ZuletztGekocht</div>)
}

{/*    {(!data || !data.length) &&
        <>
            <Typography variant="h4" gutterBottom borderBottom={2} mt={5}>
                zuletzt gekocht:
            </Typography>
            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 16}}>
              {rezeptHistory.map((entry, index) =>
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <RezeptCard key={entry._id} rezept={entry}/>
                  <IconButton
                    onClick={() => handleDeleteHistory(entry._id)}
                  ><RemoveCircleIcon/></IconButton>
                </Grid>
              )}
            </Grid>
        </>
    }*/}
