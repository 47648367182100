import React from "react";
import {FooterMainDesktop} from "./FooterMainDesktop";
import {FooterMainMobile} from "./FooterMainMobile";


export function FooterMain() {

  return (
    <>
      <FooterMainMobile/>
      <FooterMainDesktop/>
    </>);

}
